import React from 'react'
import {ThemeContext} from '../../context/ThemeContextProvider'


function FullLogo(props: React.ComponentProps<'svg'>) {
    const {themeIsDark} = React.useContext(ThemeContext)
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet" viewBox="22.36 18 1590.74 330"  fill={themeIsDark ? "#fff" : "#000"} stroke="none" {...props} >
            <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
            <g transform="translate(0.000000,364.000000) scale(0.100000,-0.100000)">
                <path d="M1725 3271 c-247 -103 -661 -274 -920 -381 -258 -107 -483 -204 -499 -215 -64 -43 -96 -123 -77 -192 20 -72 47 -102 124 -138 167 -78 1825 -758 1859 -762 51 -7 135 34 167 83 35 51 40 136 12 191 -24 49 -54 71 -139 107 -104 44 -1295 537 -1322 548 -29 11 -119 -28 789 348 411 170 614 259 634 278 58 54 74 149 38 220 -23 46 -46 67 -94 87 -74 31 -96 24 -572 -174z"></path>
                <path d="M3082 3439 c-79 -40 -120 -139 -92 -224 28 -86 14 -78 779 -395 391 -162 707 -297 703 -301 -4 -4 -149 -65 -322 -137 -878 -362 -1077 -446 -1102 -463 -35 -25 -68 -96 -68 -145 1 -118 116 -213 225 -186 22 5 454 181 960 391 614 255 929 391 947 408 79 76 71 219 -16 284 -12 9 -441 191 -954 403 -725 300 -942 386 -975 386 -23 0 -61 -10 -85 -21z"></path>
                <path d="M5920 1780 l0 -1010 45 0 45 0 0 1010 0 1010 -45 0 -45 0 0 -1010z"></path>
                <path d="M7025 2618 c-3 -8 -5 -50 -3 -94 l3 -79 73 -3 72 -3 0 -329 0 -330 -75 0 -75 0 0 -90 0 -90 343 0 c276 0 354 3 402 15 223 58 355 243 355 495 0 47 -7 112 -15 145 -48 182 -192 321 -370 355 -33 6 -205 14 -382 17 -279 5 -323 4 -328 -9z m660 -206 c113 -58 170 -204 146 -377 -25 -175 -101 -244 -283 -253 l-98 -5 0 333 0 333 98 -5 c71 -4 107 -10 137 -26z"></path>
                <path d="M9954 2617 c-3 -8 -4 -49 -2 -93 l3 -79 63 -3 62 -3 0 -329 0 -330 -65 0 -65 0 0 -90 0 -90 220 0 220 0 0 90 0 90 -65 0 -65 0 0 330 c0 182 3 330 8 330 4 0 77 -188 164 -418 l156 -417 60 -3 60 -3 142 383 c176 473 170 458 181 458 5 0 9 -143 9 -330 l0 -330 -60 0 -60 0 0 -90 0 -90 260 0 260 0 0 90 0 90 -65 0 -65 0 0 330 0 329 63 3 62 3 0 90 0 90 -256 3 c-197 2 -259 -1 -267 -10 -8 -11 -66 -163 -214 -558 -8 -22 -24 14 -117 260 -59 157 -110 291 -114 298 -12 18 -506 17 -513 -1z"></path>
                <path d="M13343 2506 l-113 -80 0 -53 0 -52 -42 -3 -43 -3 -3 -87 -3 -88 45 0 45 0 3 -224 c3 -246 4 -250 72 -300 37 -28 153 -34 230 -12 l56 17 0 84 0 84 -32 -10 c-18 -5 -45 -7 -59 -4 -35 8 -39 30 -39 216 l0 149 65 0 65 0 0 90 0 90 -65 0 -64 0 -3 133 -3 133 -112 -80z"></path>
                <path d="M8513 2315 c-261 -81 -356 -421 -173 -619 114 -123 349 -152 523 -65 47 24 142 118 130 129 -4 5 -46 19 -92 33 l-83 25 -45 -34 c-53 -41 -78 -48 -144 -42 -74 7 -132 57 -145 125 l-6 33 263 2 264 3 -3 55 c-3 76 -36 178 -72 229 -37 51 -104 100 -168 123 -57 20 -190 22 -249 3z m198 -172 c26 -22 59 -76 59 -98 0 -3 -65 -5 -144 -5 l-145 0 9 29 c21 74 56 100 137 101 42 0 58 -5 84 -27z"></path>
                <path d="M11731 2315 c-30 -9 -69 -25 -88 -36 -39 -24 -105 -99 -100 -113 2 -6 44 -18 93 -27 l89 -17 28 24 c56 47 165 40 207 -13 17 -22 30 -103 16 -103 -3 0 -35 7 -72 16 -179 41 -345 -51 -370 -204 -16 -100 51 -202 156 -237 69 -23 178 -17 237 13 52 27 53 27 53 2 0 -19 7 -20 175 -20 l175 0 0 85 0 85 -54 0 -54 0 -4 193 c-3 172 -6 196 -25 232 -28 52 -57 79 -118 106 -65 30 -260 37 -344 14z m200 -426 c45 -20 49 -24 49 -55 0 -30 -6 -38 -47 -64 -86 -55 -173 -28 -173 53 0 79 73 108 171 66z"></path>
                <path d="M12603 2320 c-85 -18 -159 -73 -183 -135 -16 -42 -14 -118 4 -153 41 -78 146 -132 320 -161 90 -16 126 -34 126 -66 0 -58 -120 -84 -200 -43 -33 17 -47 31 -55 56 l-9 32 -88 0 -88 0 0 -125 0 -125 90 0 c50 0 90 4 90 8 0 5 24 1 53 -9 94 -33 223 -20 296 29 120 81 142 237 49 338 -46 50 -100 70 -273 104 -111 22 -135 32 -135 56 0 30 31 44 101 44 75 0 121 -17 139 -50 9 -18 21 -20 96 -20 l85 0 -3 108 -3 107 -82 3 c-67 2 -83 0 -83 -12 0 -12 -6 -12 -45 -1 -62 19 -152 26 -202 15z"></path>
                <path d="M13955 2317 c-92 -31 -176 -96 -220 -172 -36 -60 -58 -168 -50 -241 12 -114 88 -229 183 -278 116 -60 310 -58 431 4 43 21 131 105 131 123 0 7 -39 24 -87 39 l-87 27 -30 -26 c-69 -58 -158 -70 -228 -31 -41 23 -78 79 -78 118 0 20 5 20 258 20 142 0 261 2 263 5 11 10 -12 156 -32 209 -27 72 -100 148 -176 183 -47 22 -76 28 -148 30 -55 2 -105 -2 -130 -10z m195 -174 c16 -16 37 -45 45 -65 l15 -38 -145 0 c-80 0 -145 2 -145 5 0 2 7 23 17 46 21 54 62 79 130 79 44 0 58 -5 83 -27z"></path>
                <path d="M15049 2317 c-69 -19 -128 -67 -162 -132 -16 -30 -16 -29 -17 53 l0 82 -167 -2 -168 -3 0 -85 0 -85 63 -3 62 -3 0 -184 0 -185 -65 0 -65 0 0 -85 0 -85 250 0 250 0 0 85 0 85 -71 0 -72 0 6 85 c12 170 81 245 225 245 l43 0 -3 113 -3 112 -30 2 c-16 1 -51 -4 -76 -10z"></path>
                <path d="M9044 2307 c-3 -8 -4 -47 -2 -88 l3 -74 54 -5 53 -5 117 -270 117 -270 101 -3 101 -3 34 83 c19 46 70 170 114 275 l79 193 53 0 53 0 -3 88 -3 87 -190 0 -190 0 0 -85 0 -85 43 -3 c23 -2 42 -6 42 -10 0 -4 -26 -72 -59 -150 l-58 -143 -52 146 c-28 81 -51 148 -51 151 0 2 18 4 40 4 l40 0 0 90 0 90 -215 0 c-171 0 -217 -3 -221 -13z"></path>
                <path d="M15260 2231 l0 -90 52 -3 52 -3 127 -283 127 -282 -22 -58 -22 -57 -70 -5 -69 -5 -3 -83 -3 -83 132 3 131 3 167 425 166 425 53 3 53 3 -3 87 -3 87 -185 0 -185 0 -3 -87 -3 -87 45 -3 44 -3 -57 -142 c-31 -79 -58 -143 -61 -143 -3 0 -28 64 -56 143 l-52 142 39 3 40 3 -3 87 -3 87 -212 3 -213 2 0 -89z"></path>
                <path d="M1130 1323 l0 -486 25 -43 c14 -23 39 -51 57 -62 18 -11 347 -144 731 -296 546 -216 707 -276 742 -276 36 0 186 57 716 270 369 149 687 278 706 286 19 10 44 34 58 59 l25 42 -2 495 -3 495 -180 -76 -180 -77 -3 -321 c-2 -243 -5 -322 -15 -326 -6 -3 -270 -107 -584 -231 l-573 -226 -572 226 c-315 124 -579 228 -585 231 -10 4 -13 80 -13 332 l0 327 -42 16 c-24 9 -103 41 -175 71 l-133 55 0 -485z"></path>
                <path d="M7392 1232 c-94 -34 -150 -109 -159 -212 -11 -119 54 -223 158 -254 137 -41 267 2 319 105 11 21 20 42 20 47 0 5 -22 19 -49 30 l-50 20 -11 -33 c-18 -53 -62 -85 -119 -85 -33 0 -56 6 -74 20 -36 29 -61 100 -53 151 14 89 57 129 138 129 46 0 74 -17 103 -62 14 -23 25 -28 56 -28 l39 0 0 90 0 90 -40 0 c-27 0 -43 -5 -46 -15 -5 -13 -9 -13 -35 0 -48 25 -137 28 -197 7z"></path>
                <path d="M8133 1236 c-134 -44 -203 -159 -174 -291 32 -143 180 -224 340 -185 222 53 259 356 55 456 -65 32 -159 40 -221 20z m147 -101 c79 -41 90 -200 18 -260 -44 -37 -119 -36 -158 3 -50 51 -60 142 -24 214 27 53 105 73 164 43z"></path>
                <path d="M10832 1232 c-104 -37 -164 -125 -164 -238 0 -146 106 -244 266 -244 144 0 240 77 261 208 l7 42 -122 0 -121 0 3 -42 c3 -42 4 -43 41 -46 44 -4 46 -13 11 -41 -36 -29 -112 -29 -148 0 -14 11 -33 34 -41 49 -20 39 -19 135 1 170 48 81 187 81 229 -1 15 -27 20 -30 58 -27 l42 3 0 85 0 85 -42 3 c-34 2 -43 0 -43 -12 0 -20 -4 -20 -40 -1 -49 25 -137 28 -198 7z"></path>
                <path d="M11866 1235 c-51 -18 -74 -41 -86 -86 -21 -78 11 -127 128 -191 68 -38 73 -42 70 -71 -6 -64 -83 -62 -104 3 -11 32 -15 35 -50 35 l-39 0 -3 -82 -3 -83 46 0 c25 0 45 5 45 10 0 6 13 4 30 -5 60 -31 146 -8 181 49 24 40 24 111 0 156 -13 26 -39 46 -97 77 -82 44 -106 66 -97 89 14 37 84 19 98 -26 5 -16 15 -20 51 -20 l44 0 0 75 0 75 -45 0 c-35 0 -45 -4 -45 -16 0 -13 -3 -14 -14 -5 -27 23 -71 29 -110 16z"></path>
                <path d="M12492 1231 c-101 -35 -161 -119 -162 -225 0 -109 40 -184 123 -229 34 -18 58 -22 132 -22 80 0 95 3 137 28 48 28 80 64 97 111 10 24 8 27 -39 51 l-49 25 -20 -44 c-22 -52 -61 -76 -119 -76 -71 0 -122 61 -122 145 0 98 49 155 133 155 47 0 53 -3 93 -46 37 -39 47 -45 76 -42 l33 3 3 88 3 87 -46 0 c-31 0 -45 -4 -45 -13 0 -11 -11 -10 -52 4 -67 23 -109 23 -176 0z"></path>
                <path d="M13947 1240 c-194 -49 -256 -283 -112 -423 99 -96 300 -87 393 18 111 126 67 328 -86 392 -43 18 -147 25 -195 13z m148 -112 c34 -27 55 -75 55 -128 0 -91 -51 -150 -129 -150 -78 1 -121 51 -121 143 0 65 17 105 56 136 36 29 102 28 139 -1z"></path>
                <path d="M14708 1237 c-214 -61 -243 -354 -44 -460 59 -31 203 -32 261 0 88 47 137 127 137 224 0 99 -57 187 -145 224 -53 22 -152 28 -209 12z m156 -108 c38 -30 56 -71 56 -129 0 -97 -45 -150 -128 -150 -71 0 -122 63 -122 150 0 53 21 101 55 128 37 29 103 30 139 1z"></path>
                <path d="M8710 1195 c0 -45 0 -45 35 -45 l35 0 0 -150 0 -150 -35 0 c-35 0 -35 0 -35 -45 l0 -45 173 0 c145 0 178 3 212 19 80 36 129 118 129 216 0 70 -20 121 -67 172 -56 61 -104 73 -294 73 l-153 0 0 -45z m305 -59 c44 -19 69 -66 69 -136 2 -104 -38 -150 -129 -150 l-45 0 0 150 0 150 35 0 c20 0 51 -6 70 -14z"></path>
                <path d="M9440 1195 c0 -45 0 -45 35 -45 l35 0 0 -149 0 -150 -32 -3 c-31 -3 -33 -6 -36 -45 l-3 -43 141 0 141 0 -3 43 c-3 39 -5 42 -35 45 l-33 3 0 150 0 149 35 0 c35 0 35 0 35 45 l0 45 -140 0 -140 0 0 -45z"></path>
                <path d="M9920 1195 c0 -43 1 -45 30 -45 l30 0 0 -149 0 -150 -27 -3 c-25 -3 -28 -7 -31 -45 l-3 -43 111 0 111 0 -3 43 c-3 38 -6 42 -30 45 l-28 3 0 117 0 117 54 -91 c29 -50 73 -126 97 -168 l43 -77 56 3 55 3 3 198 2 197 30 0 c29 0 30 2 30 45 l0 45 -110 0 -110 0 0 -45 c0 -43 1 -45 30 -45 l30 0 0 -92 -1 -93 -25 40 c-13 22 -50 84 -81 138 l-57 97 -103 0 -103 0 0 -45z"></path>
                <path d="M13034 1226 c-3 -8 -4 -27 -2 -43 2 -22 9 -29 31 -31 l27 -3 0 -149 0 -149 -27 -3 c-25 -3 -28 -7 -31 -45 l-3 -43 120 0 121 0 0 45 c0 41 -2 45 -25 45 -24 0 -25 3 -25 60 l0 60 60 0 60 0 0 -60 c0 -53 -2 -60 -20 -60 -17 0 -20 -7 -20 -45 l0 -45 115 0 115 0 0 45 c0 43 -1 45 -30 45 l-30 0 0 150 0 150 30 0 c29 0 30 2 30 45 l0 45 -115 0 -115 0 0 -45 c0 -38 3 -45 20 -45 17 0 20 -7 20 -45 l0 -45 -60 0 -60 0 0 45 c0 41 2 45 25 45 23 0 25 4 25 45 l0 45 -115 0 c-91 0 -117 -3 -121 -14z"></path>
                <path d="M15284 1226 c-3 -8 -4 -27 -2 -43 2 -24 8 -28 36 -31 l32 -3 0 -149 0 -149 -32 -3 c-31 -3 -33 -6 -36 -45 l-3 -43 220 0 221 0 0 115 0 115 -50 0 -50 0 0 -70 0 -70 -65 0 -65 0 0 149 0 150 33 3 c30 3 32 5 32 43 l0 40 -133 3 c-108 2 -133 0 -138 -12z"></path>
            </g>
        </svg>
    )
}

export default FullLogo
