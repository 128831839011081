import * as React from "react"
import FullLogo from "../components/devmastery/FullLogo"


const IndexPage = () => {
  return (
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <FullLogo style={{width: '90%', maxWidth: 400, marginBottom: 40}} />
    </div>
  )
}

export default IndexPage
